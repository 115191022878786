import React from "react";
import { Image, Provider, Heading, Subhead, Flex } from "rebass";
import { Hero, CallToAction, Phone } from "react-landing-page";
import screen1 from "./images/screen1.png";
import screen2 from "./images/screen2.png";
import logo from "./images/paid-logo.png";

const App = props => (
  <Provider>
    <Hero color="black" bg="white" backgroundImage="">
      <Flex flexWrap="wrap" alignItems="center">
        <Flex alignItems="flex-start" width={[1, 1 / 2]} p={3}>
          <Phone
            src={screen2}
            notch
            style={{ transform: "translate(32px, 64px)" }}
          />
          <Phone
            src={screen1}
            color="white"
            style={{ transform: "translate(-32px, 0px)" }}
          />
        </Flex>
        <Flex
          width={[1, 1 / 2]}
          alignItems="center"
          flexDirection="column"
          p={3}
        >
          <Heading alignItems="center" mt={5}>
            <Image src={logo} width={150} mb={2} />
          </Heading>
          <Subhead fontSize={[2, 3]} color={"#4e4f4f"} alignItems="center">
            Do Tasks. Get Paid Bitcoin.
          </Subhead>
          <Flex mt={3} flexWrap="wrap" justifyContent="center">
            <CallToAction
              bg="#007aff"
              href="https://testflight.apple.com/join/coabl0RA"
              target="_blank"
              mt={1}
              mr={1}
            >
               App store
            </CallToAction>
            <CallToAction
              bg="#007aff"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=co.paid"
              mt={1}
              mr={1}
            >
              Google Play
            </CallToAction>
          </Flex>
        </Flex>
      </Flex>
    </Hero>
  </Provider>
);

export default App;
